<template>
  <div class="wrapper">
    <div class="text-h4 font-weight-bold pa-1 title">
      Документы
    </div>
    <BaseDocuments
      :value-docs="valueDocs"
      :accept-types="acceptTypes"
      :model-name="modelName"
      :model-id="modelId"
      :disabled="disabled"
      :multiple="multiple"
      :value="value"
      :name-object-doc="nameObjectDoc"
      :with-description="withDescription"
    />
  </div>
</template>

<script>
import BaseDocuments from '@/components/base/BaseDocuments'

export default {
  name: 'MapDocuments',
  components: { BaseDocuments },
  props: {
    valueDocs: {
      type: Array
    },
    acceptTypes: {
      type: String,
      default: 'application/pdf'
    },
    modelName: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: false
    },
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor"
    multiple: { Boolean, default: true }, //(нужен для отключения multiple) пропс передается из Appeal/DetailView, как false, в самом BaseUploaderFile стоит по умолчанию true
    value: Object,
    nameObjectDoc: String,
    withDescription: Boolean
  }
}
</script>

<style scoped>
  .wrapper {
    height: calc(100vh - 100px)
  }
  .title {
    color: #64C7FF;
  }
</style>